import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { useAuth } from '../../auth/AuthProvider';
import { NavigationButton } from '../styles/Buttons';
import { useIsAdmin, useIsUser } from '../../requests/reports';

const Wrapper = styled.div<{ scrollPos: number; cookie: boolean; fixedMode: boolean }>`
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    z-index: 10000;
    justify-content: space-between;
    position: ${(props) => (props.fixedMode ? 'fixed' : props.scrollPos > 800 ? 'sticky' : 'absolute')};
    background: ${(props) => !props.fixedMode && props.scrollPos > 800 && 'black'};
    border-bottom: ${(props) => !props.fixedMode && props.scrollPos > 800 && '1px solid rgba(255, 255, 255, 0.1)'};
    ${(props) =>
        !props.fixedMode &&
        props.scrollPos > 800 &&
        css`
            animation: ${fadeIn} 0.5s ease-in-out;
        `}
    top: ${(props) => (props.cookie ? '50px' : '0')};
    left: 0;
    pointer-events: none;
    a {
        text-decoration: none;
        color: white;
        margin-left: 25px;
    }
`;

const fadeIn = keyframes`
  from { top: -50px; }
  to   { top: 0; }
`;

const LogoutButton = styled(NavigationButton)`
    margin-left: 25px;
    cursor: pointer;
`;

const LoginButton = styled(NavigationButton)`
    cursor: pointer;
`;

const NavigationButtons = styled.div<{ scrollPos: number; fixedMode: boolean }>`
    display: flex;
    margin-right: 25px;
    pointer-events: auto;
    ${NavigationButton} {
        border: ${(props) => !props.fixedMode && props.scrollPos > 800 && 'none'};
    }
    ${LogoutButton} {
        border: ${(props) => !props.fixedMode && props.scrollPos > 800 && 'none'};
    }
`;

const Logo = styled.img`
    pointer-events: auto;
`;

const Navbar: FC<{ visible: Boolean; setCookieVisibility: Function; consented: Boolean }> = ({
    visible,
    setCookieVisibility,
    consented,
}) => {
    const [scrollTop, setScrollTop] = useState(0);
    const { token, logout } = useAuth();
    const { data: isAdmin } = useIsAdmin(token);
    const { data: isUser } = useIsUser(token);
    const [showNavButtons, setShowNavButtons] = useState(false);

    const location = useLocation();
    useEffect(() => {
        if (isAdmin) {
            setCookieVisibility(false);
        }
    }, [isAdmin, setCookieVisibility]);
    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop]);

    useEffect(() => {
        const pagesWithoutNavigationButtons = [
            '/admin',
            '/overwatch-reports',
            '/visualize_candidates',
            '/clearview',
            '/demo',
            '/overwatch',
            '/search',
            '/user',
        ];
        let locationFoundInArray: boolean = false;
        for (let i = 0; i < pagesWithoutNavigationButtons.length; i++) {
            if (location.pathname.startsWith(pagesWithoutNavigationButtons[i])) {
                locationFoundInArray = true;
            }
        }
        if (locationFoundInArray) {
            setShowNavButtons(false);
        } else {
            setShowNavButtons(true);
        }
    }, [location]);

    return (
        <Wrapper
            scrollPos={scrollTop}
            cookie={Boolean(!consented && visible)}
            fixedMode={Boolean(location.pathname.startsWith('/story'))}
        >
            <Link to="/">
                <Logo src="/images/vakeLogo.svg" alt="VAKE" />
            </Link>
            <NavigationButtons scrollPos={scrollTop} fixedMode={Boolean(location.pathname.startsWith('/story'))}>
                {showNavButtons && (
                    <>
                        <Link to="/careers">
                            <NavigationButton>Careers</NavigationButton>
                        </Link>
                        <Link to="/about">
                            <NavigationButton>About</NavigationButton>
                        </Link>
                        <Link to="/press-release">
                            <NavigationButton>News</NavigationButton>
                        </Link>
                    </>
                )}
                {token && isAdmin && (
                    <Link to="/admin">
                        <NavigationButton id="admin-button">Admin</NavigationButton>
                    </Link>
                )}
                {token && !isAdmin && isUser && (
                    <Link to="/user">
                        <NavigationButton>Dashboard</NavigationButton>
                    </Link>
                )}
                {token ? (
                    <LogoutButton onClick={() => logout()}>Log out</LogoutButton>
                ) : (
                    <Link to="/login">
                        <LoginButton>Log in</LoginButton>
                    </Link>
                )}
            </NavigationButtons>
        </Wrapper>
    );
};

export default Navbar;
