export const prodUrl = 'https://vake.ai';

export const getEnv = () => {
    if (window.location.hostname === 'landing-test.vake.ai') {
        return 'test';
    } else if (
        window.location.hostname === 'landing-prod.vake.ai' ||
        window.location.hostname === 'vake.ai' ||
        window.location.hostname === 'www.vake.ai'
    ) {
        return 'prod';
    } else if (window.location.hostname === 'landing-snap0.vake.ai') {
        return 'snap0';
    } else if (window.location.hostname === 'landing-snap1.vake.ai') {
        return 'snap1';
    } else if (window.location.hostname === 'localhost') {
        return 'localhost';
    } else {
        throw new Error('Invalid hostURL');
    }
};

export const apiBaseUrl = () => {
    const env = getEnv();
    if (env === 'test') {
        return 'https://api-test.vake.ai';
    } else if (env === 'prod') {
        return 'https://api-prod.vake.ai';
    } else if (env === 'snap0') {
        return 'https://api-snap0.vake.ai';
    } else if (env === 'snap1') {
        return 'https://api-snap1.vake.ai';
    } else if (env === 'localhost') {
        return 'http://localhost:8000';
    } else {
        throw new Error('Invalid hostURL');
    }
};
