import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useInput } from '../components/useInput';
import { InputField } from '../../../shared/styles/InputFields';
import { StyledInputButton } from '../../../shared/styles/Buttons';
import constants from '../../../shared/constants';
import { orderOverwatchSubscription } from '../../../requests/reports';
import { SimpleDateSelector } from '../interactiveReport/reportInfo/DateSelector';
import { useAuth } from '../../../auth/AuthProvider';
import { OrderOverwatchMap } from './OrderOWMap';
import { Feature } from 'geojson';
import { PaddingWrapper, RoundedWrapper } from '../styles/Wrappers';
import { QuestionBall } from '../components/PathfinderLeftSidebar';
import { Tooltip } from 'react-tooltip';
import { DrawButton } from '../../../shared/components/DrawToggler';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const SideBar = styled.div`
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1000;
    min-width: 175px;
    width: 20%;
    max-width: 375px;
    length: 100vh;
    label {
        margin-bottom: 12px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const Title = styled(PaddingWrapper)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
`;

const InputWrapper = styled(PaddingWrapper)`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        ${InputField} {
            width: 100%;
        }
    }
`;

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    wrap: wrap;
`;

const StyledInputField = styled(InputField)`
    width: 100%;
    line-height: normal;
    ::placeholder {
        color: grey; /* Change 'grey' to any color you prefer */
    }
`;

const ErrorMessage = styled.div`
    width: fit-content;
    text-align: center;
    margin-bottom: 10px;
    background-color: #4d5354;
    padding: 10px;
    align-self: center;
`;

const xDaysAgo = (daysAgo: number): Date => {
    let date = new Date();
    date.setUTCDate(date.getUTCDate() - daysAgo);
    return date;
};

const getExtendedDate = (date: Date | null, fromOrTo: string) => {
    // Extends the date search area by a day before and after
    if (date) {
        const extendedDate = new Date(date.getTime()); // Deep copy of date object
        if (fromOrTo === 'from') {
            extendedDate.setDate(extendedDate.getDate() - 1);
            return extendedDate;
        } else if (fromOrTo === 'to') {
            extendedDate.setDate(extendedDate.getDate() + 1);
            return extendedDate;
        }
    }
    return null;
};

export enum SubscriptionState {
    success,
    failed,
    unsent,
}

// TODO: Prevent user to set fromDate later than toDate
const OrderOverwatchReport: FC = () => {
    const { token } = useAuth();
    const [orderSubscriptionState, setOrderSubscriptionState] = useState<SubscriptionState>(SubscriptionState.unsent);
    const [reportPath, setReportPath] = useState<string | undefined>(undefined);
    const [fromDate, setFromDate] = useState<Date | null>(xDaysAgo(14));
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [drawMode, toggleDrawMode] = useState<boolean>(false);
    const [polygon, setPolygon] = useState<Feature | undefined>(undefined);
    const [areDatesValid, setAreDatesValid] = useState<boolean>(false);
    const [isAoiValid, setIsAoiValid] = useState<boolean>(false);
    const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false);
    const [allFieldsValid, setAllFieldsValid] = useState<boolean>(false);
    const [AoiTooLargeError, setAoiTooLargeError] = useState<boolean>(false);
    useEffect(() => {
        document.title = 'Order Overwatch';
    }, []);

    const description = useInput('', 'Description', []);

    const checkAreAllFieldsValid = useCallback(() => {
        setAllFieldsValid(areDatesValid && isAoiValid && isDescriptionValid);
    }, [areDatesValid, isAoiValid, isDescriptionValid, setAllFieldsValid]);

    useEffect(() => {
        setIsDescriptionValid(description.value.length > 0);
        checkAreAllFieldsValid();
    }, [description, checkAreAllFieldsValid]);

    useEffect(() => {
        let dateCheck = false;
        if (fromDate && toDate && fromDate < toDate) {
            // Redundancy for calender mishappenings
            const diff = toDate.getTime() - fromDate.getTime();
            if (diff < 12960000000) {
                // 1000 * 60 * 60 * 24 * 30 * 5 is 5 months
                dateCheck = true;
                setAoiTooLargeError(false);
            } else {
                setAoiTooLargeError(true);
            }
        }
        setAreDatesValid(dateCheck);
        checkAreAllFieldsValid();
    }, [fromDate, toDate, checkAreAllFieldsValid]);

    useEffect(() => {
        if (polygon?.geometry.type === 'Polygon') {
            setIsAoiValid(polygon?.geometry.coordinates[0].length > 0);
        } else {
            setIsAoiValid(false);
        }
        checkAreAllFieldsValid();
    }, [polygon, checkAreAllFieldsValid]);

    const resetFields = useCallback(() => {
        description.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orderSubscriptionCallback = (requestSuccess: SubscriptionState, reportPath: string | undefined) => {
        if (requestSuccess) resetFields();
        setOrderSubscriptionState(requestSuccess);
        setReportPath(window.location.origin + '/overwatch/' + reportPath);
    };

    const submitLoginForm = (e: any) => {
        e.preventDefault();
        if (allFieldsValid && polygon?.geometry.type === 'Polygon' && toDate) {
            const geometryString = JSON.stringify(polygon.geometry);
            orderOverwatchSubscription(
                geometryString,
                description.value,
                getExtendedDate(fromDate, 'from')!,
                getExtendedDate(toDate, 'to'),
                token,
                (orderSubscriptionState: SubscriptionState, reportPath: string | undefined) =>
                    orderSubscriptionCallback(orderSubscriptionState, reportPath)
            );
        }
    };

    return (
        <Wrapper>
            <SideBar>
                <RoundedWrapper>
                    <Title id="report_name">
                        <h2>Order Overwatch</h2>
                    </Title>
                    {orderSubscriptionState !== SubscriptionState.success ? (
                        <form onSubmit={(e) => submitLoginForm(e)}>
                            <InputWrapper>
                                <InputRow>
                                    <StyledInputField
                                        id="description"
                                        type="description"
                                        placeholder="Name your report"
                                        error={description.validation.errors.length > 0}
                                        {...description.bind}
                                    />
                                </InputRow>
                                <InputRow>
                                    <DrawButton
                                        isFinished={polygon !== undefined}
                                        isDrawMode={drawMode}
                                        onClick={() => {
                                            setPolygon(undefined);
                                            toggleDrawMode(!drawMode);
                                        }}
                                    />
                                    <QuestionBall
                                        id="draw_help"
                                        data-tooltip-html=" Press the button and draw the area of interest by clicking around the
                                                map. </br> Close the drawing by clicking the same place twice. Larger areas
                                                can take longer to process."
                                    >
                                        ?
                                    </QuestionBall>
                                    <Tooltip anchorId={`draw_help`} place="right" />
                                </InputRow>
                                <InputRow>
                                    <h3>Analysis duration</h3>
                                    <QuestionBall
                                        id="processing_time"
                                        data-tooltip-html=" When do you want processing to start and end? </br> Here you can choose a
                                                future date and the report will </br> update automatically when new images are
                                                avaliable."
                                    >
                                        ?
                                    </QuestionBall>
                                    <Tooltip anchorId={`processing_time`} place="right" />
                                </InputRow>
                                <InputRow>
                                    <span>Start Date</span>
                                    <SimpleDateSelector
                                        buttonText={fromDate !== null ? fromDate.toDateString().slice(4) : 'Start Date'}
                                        date={fromDate}
                                        setDate={setFromDate}
                                        showYearDropdown={true}
                                    />
                                </InputRow>

                                <InputRow>
                                    <span>End Date</span>
                                    <SimpleDateSelector
                                        buttonText={toDate !== null ? toDate.toDateString().slice(4) : 'End Date'}
                                        date={toDate}
                                        setDate={setToDate}
                                        showYearDropdown={true}
                                    />
                                </InputRow>

                                {AoiTooLargeError ? (
                                    <InputWrapper>
                                        <ErrorMessage>Duration cannot exceed 5 months</ErrorMessage>
                                    </InputWrapper>
                                ) : null}

                                <StyledInputButton
                                    background={constants.colors.pallet.white}
                                    disabled={!allFieldsValid}
                                    active={allFieldsValid}
                                    type="submit"
                                    value="Order"
                                />
                            </InputWrapper>
                            {orderSubscriptionState === SubscriptionState.failed && (
                                <InputWrapper>
                                    <ErrorMessage style={{ marginBottom: '0px', marginTop: '10px' }}>
                                        Order failed, try again or contact VAKE
                                    </ErrorMessage>
                                </InputWrapper>
                            )}
                        </form>
                    ) : reportPath ? (
                        <p style={{ color: constants.colors.pallet.white }}>
                            Order sent successfully. In some minutes, you will find the report{' '}
                            <Link to={reportPath}>here.</Link>
                        </p>
                    ) : (
                        <p style={{ color: constants.colors.pallet.white }}>
                            Order sent successfully. In some minutes, you will find the report at{' '}
                            <Link to={'https://www.vake.ai/overwatch-reports'}> your list of overwatch reports.</Link>
                        </p>
                    )}
                </RoundedWrapper>
            </SideBar>
            <OrderOverwatchMap
                drawMode={drawMode}
                toggleDrawMode={toggleDrawMode}
                polygon={polygon}
                setPolygon={setPolygon}
            />
        </Wrapper>
    );
};

export default OrderOverwatchReport;
