import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../shared/styles/Buttons';
import constants from '../shared/constants';
import { Spinner } from '../shared/styles/Spinner';
import { useQuery } from 'react-query';

import { getRequestHeaders, vake_api_server_url } from '../requests/helpers';
import { useAuth } from '../auth/AuthProvider';
import { useIsAdmin } from '../requests/reports';

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    text-align: center;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const TextWrapper = styled.span`
    font-size: 13px;
    line-height: 17px;
    a {
        text-decoration: underline;
        color: rgba(255, 255, 255, 0.5);
    }
`;
const CompanyWrapper = styled.div`
    display: grid;
    grid-template-rows: 200px 200px;
    grid-template-columns: 25% 25% 25% 25%;
    grid-gap: 1px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const GridCell = styled.div<{ $rightColumn?: boolean | null; $lowestRow?: boolean | null }>`
    background: black;
    border-right: 2px solid ${(props) => (props.$rightColumn ? 'transparent' : 'rgba(255, 255, 255, 0.5)')};
    border-bottom: 2px solid ${(props) => (props.$lowestRow ? 'transparent' : 'rgba(255, 255, 255, 0.5)')};
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.5);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 120px;
    max-width: ${constants.sizes.container};
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: 100%;
    }
`;

export interface Site {
    title: string;
    url: string;
    text: string;
}

const useGetAllSites = () => {
    const { token } = useAuth();
    let url = `${vake_api_server_url}/admin/get-admin-sites`;
    return useQuery<Site[]>(['admin_sites'], async () => {
        const res = await fetch(url, {
            credentials: 'include',
            headers: getRequestHeaders(token),
        });

        if (!res.ok) {
            throw new Error(res.statusText);
        }

        return await res.json();
    });
};

export const GridPageDisplay: FC<{ websites: Site[] }> = ({ websites }) => {
    const remain = ((websites.length - 1) % 4) + 2;
    return (
        <CompanyWrapper>
            {websites &&
                websites.map((site, i) => {
                    return (
                        <GridCell key={i} $rightColumn={i % 4 === 3} $lowestRow={i > websites.length - remain}>
                            <Link to={site.url}>
                                <Button
                                    style={{ width: '90%', height: '80%' }}
                                    background={
                                        site.url !== '/admin'
                                            ? constants.colors.pallet.blue
                                            : constants.colors.pallet.disabled
                                    }
                                >
                                    {site.title}
                                </Button>
                            </Link>
                            <TextWrapper>{site.text}</TextWrapper>
                        </GridCell>
                    );
                })}
        </CompanyWrapper>
    );
};

const Admin: FC<any> = () => {
    const { data: adminSites, isLoading, isError } = useGetAllSites();
    const { token } = useAuth();
    const { data: isAdmin } = useIsAdmin(token);

    if (isError || !isAdmin) {
        return (
            <Container>
                <div>Fetching data failed</div>
            </Container>
        );
    }
    if (isLoading) {
        return (
            <Wrapper>
                <AbsoluteSpinner />
            </Wrapper>
        );
    }
    return <Wrapper>{typeof adminSites !== 'undefined' ? <GridPageDisplay websites={adminSites} /> : null}</Wrapper>;
};

export default Admin;
