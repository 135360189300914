import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useInput } from '../components/useInput';
import { InputField } from '../../../shared/styles/InputFields';
import { StyledInputButton } from '../../../shared/styles/Buttons';
import constants from '../../../shared/constants';
import { orderPathfinderSubscription, useIsAdmin } from '../../../requests/reports';
import { SimpleDateSelector } from '../interactiveReport/reportInfo/DateSelector';
import { useAuth } from '../../../auth/AuthProvider';
import { AbsoluteSpinner } from '../interactiveReport/PathfinderReport';

const OrderReportWrapper = styled.div`
    width: calc(${constants.sizes.container} - 800px);
    max-width: calc(100% - 100px);
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    background: radial-gradient(42.95% 171.27% at 86.22% 0%, rgba(0, 161, 255, 0.65) 0%, rgba(0, 161, 255, 0.5) 100%);

    margin: auto;
    padding: 25px;
    margin-top: 300px;
    margin-bottom: 100px;

    p {
        margin: 0;
        margin-bottom: 10px;
    }
    h2 {
        margin: 0 0 15px 0;
    }
    label {
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 250% */

        letter-spacing: 0.07em;

        color: rgba(255, 255, 255, 0.8);
        a {
            color: rgba(255, 255, 255, 0.8);
        }
    }
    form {
        margin-bottom: 10px;
    }

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        align-items: center;
        width: calc(100% - 50px);
        p {
            margin: 0;
            width: 100%;
        }
    }
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${InputField} {
        width: calc(100% - 50px);
    }
    margin-left: 10%;
    margin-right: 10%;
    margin- @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
    }
`;

const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    label {
        margin-right: 20px;
    }
`;

const StyledInputField = styled(InputField)`
    line-height: normal;
    line-height: 10px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ErrorMessageContainer = styled.div`
    background-color: #c95b5b;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
`;

//Validation:
const required = (text: string, fieldName: string) => {
    if (text) {
        return '';
    }
    return `${fieldName} is required`;
};

const validMmsi = (value: string, fieldName: string) => {
    const onlyNumbersRegex = /^\d+$/;
    if (onlyNumbersRegex.test(value) && value.length === 9) {
        return '';
    }
    return `${fieldName} is not a valid MMSI`;
};

const xMonthsAgo = (months: number): Date => {
    let date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
};

const getDate = (date: Date | null) => {
    if (date) return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return date;
};

// TODO: Prevent user to set fromDate later than toDate
const OrderPathfinderReport: FC = () => {
    const { token } = useAuth();
    const { isLoading, isFetched } = useIsAdmin(token);
    const [orderSubscriptionSuccess, setOrderSubscriptionSuccess] = useState<boolean | undefined>(undefined);
    const [reportPath, setReportPath] = useState<string | undefined>(undefined);
    const [fromDate, setFromDate] = useState<Date | null>(xMonthsAgo(3));
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [reportName, setReportName] = useState<string>('');

    useEffect(() => {
        document.title = 'Order Pathfinder';
    }, []);

    const mmsi = useInput('', 'MMSI', [required, validMmsi]);

    const isTimeRangeValid = useMemo(() => {
        if (fromDate && toDate) {
            const timeDifference = toDate.getTime() - fromDate.getTime();
            if (timeDifference > 34992000000) {
                // 1000 * 60 * 60 * 24 * 30 * 13,5 is about 13 months
                setErrorMessage('Time period has to be shorter than 13 months.');
                return false;
            }
            if (timeDifference < 432000000) {
                // 1000 * 60 * 60 * 24 * 5 is five days
                setErrorMessage('Time period has to be longer than 5 days.');
                return false;
            }
            setErrorMessage(null);
            return true;
        }
        return false;
    }, [fromDate, toDate]);

    const addDayToDate = (chosenDate: Date | null) => {
        if (chosenDate) {
            chosenDate.setDate(chosenDate.getDate() + 1);
            setToDate(chosenDate);
        }
    };

    const allFieldsValid = useMemo(() => {
        return Boolean(mmsi.validation.touched && !mmsi.validation.errors.length && isTimeRangeValid);
    }, [mmsi, isTimeRangeValid]);

    const resetFields = useCallback(() => {
        mmsi.reset();
        setReportName('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orderSubscriptionCallback = (requestSuccess: boolean, reportPath: string | undefined) => {
        if (requestSuccess) resetFields();
        setOrderSubscriptionSuccess(requestSuccess);
        setReportPath(reportPath);
    };

    const submitLoginForm = (e: any) => {
        e.preventDefault();
        if (allFieldsValid) {
            orderPathfinderSubscription(
                mmsi.value,
                getDate(fromDate)!,
                getDate(toDate),
                reportName,
                token,
                (orderSubscriptionSuccess: boolean, reportPath: string | undefined) =>
                    orderSubscriptionCallback(orderSubscriptionSuccess, reportPath)
            );
        }
    };

    return (
        <OrderReportWrapper>
            {isLoading && <AbsoluteSpinner />}
            {isFetched && (
                <>
                    <h2 style={{ textAlign: 'center' }}>Order VAKE Pathfinder</h2>
                    <form onSubmit={(e) => submitLoginForm(e)}>
                        <InputWrapper>
                            <InputRow>
                                <label style={{}} htmlFor="mmsi">
                                    MMSI
                                </label>
                                <StyledInputField
                                    id="mmsi"
                                    type="mmsi"
                                    error={mmsi.validation.errors.length > 0}
                                    {...mmsi.bind}
                                />
                            </InputRow>
                            <InputRow>
                                <label style={{}} htmlFor="report_name">
                                    Report Name
                                </label>
                                <StyledInputField
                                    id="report_name"
                                    type="report_name"
                                    value={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                />
                            </InputRow>
                            <InputRow>
                                <label htmlFor="start-date">Start date</label>
                                <SimpleDateSelector
                                    buttonText={fromDate !== null ? fromDate.toDateString().slice(4) : 'Start Date'}
                                    date={fromDate}
                                    setDate={setFromDate}
                                    showYearDropdown={true}
                                />
                            </InputRow>
                            <InputRow>
                                <label htmlFor="end-date">End date</label>
                                <SimpleDateSelector
                                    buttonText={toDate !== null ? toDate.toDateString().slice(4) : 'End Date'}
                                    date={toDate}
                                    setDate={addDayToDate}
                                    showYearDropdown={true}
                                />
                            </InputRow>
                        </InputWrapper>
                        {errorMessage && <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>}
                        <ButtonWrapper>
                            <StyledInputButton
                                background={constants.colors.pallet.white}
                                disabled={!allFieldsValid}
                                active={allFieldsValid}
                                type="submit"
                                value="Order"
                            />
                        </ButtonWrapper>
                    </form>
                    {orderSubscriptionSuccess && reportPath && (
                        <p style={{ color: constants.colors.pallet.white }}>
                            Order sent successfully. In some minutes, you will find the report{' '}
                            <Link to={reportPath}>here.</Link>
                        </p>
                    )}
                    {orderSubscriptionSuccess === false && (
                        <p style={{ color: constants.colors.pallet.error }}>Order failed.</p>
                    )}
                </>
            )}
        </OrderReportWrapper>
    );
};

export default OrderPathfinderReport;
