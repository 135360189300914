import { FC, useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { ReportInfo } from './reportInfo/ReportInfo';
import {
    useMetadataQuery,
    useAvailableDatesQuery,
    useInsightsPerDateQuery,
    useCloudsPerDateQuery,
    useCustomDataQuery,
} from '../../../requests/reports';
import { Spinner } from '../../../shared/styles/Spinner';
import { ReportType } from '../../../utils/enums';
import mapboxgl from 'mapbox-gl';
import { parse, ParsedQuery } from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { useShepherdTour } from 'react-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import { OverWatchSteps } from '../../../utils/tutorials';
import RMap from '../../../shared/components/OverwatchMap';
import OverwatchRight from '../components/OverwatchRight';
import { Feature, FeatureCollection } from 'geojson';
import { findIndexOfDateInArray, getDateISOString, isValidDateString } from '../../../utils/date';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true,
        },
    },
    useModalOverlay: true,
};

const createNewQueryStringWithUpdatedDate = (availableDates: Date[], index: number, searchParams: any): string => {
    const { date: _, ...otherQueries } = searchParams;
    const oldQuery = Object.keys(otherQueries).map((key) => {
        return encodeURIComponent(key) + '=' + otherQueries[key];
    });
    const parseSymbol = oldQuery.length > 0 ? '&' : '';
    const newQuery = oldQuery + parseSymbol + 'date=' + getDateISOString(availableDates[index]);
    return newQuery;
};

export const InteractiveReport: FC = () => {
    const params = useParams();
    const [searchParams] = useState<ParsedQuery<String>>(parse(useLocation().search));
    const { data: availableDates } = useAvailableDatesQuery(params, searchParams);
    const { data: metaData, isSuccess: isMetadataSuccess } = useMetadataQuery(
        params,
        ReportType.InteractiveReport,
        searchParams
    );
    const [showProcessingStatus, setProcessingStatus] = useState<boolean>(false);
    const [activeDate, setActiveDate] = useState<Date | undefined>(undefined);
    const [index, setIndex] = useState<number>(-1);
    const [detectionConfidence, setDetectionConfidence] = useState<number>(0.5);
    const [map, setMap] = useState(undefined);
    const [visibleLayers, setVisibleLayers] = useState<string[] | undefined>(undefined);
    const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
    const [hoveredFeature, setHoveredFeature] = useState<Feature | null>(null);
    const popupRef = useRef(new mapboxgl.Popup({ offset: 15 }));
    const tour = useShepherdTour({ tourOptions, steps: OverWatchSteps });

    // set page tab title
    useEffect(() => {
        let title = 'Overwatch';
        if (metaData && metaData.metadata && metaData.metadata.name) {
            title = metaData.metadata.name;
        }
        document.title = title;
    }, [isMetadataSuccess, metaData]);

    // index is set to the latest known position when available dates are initially loaded
    useEffect(() => {
        if (index === -1 && availableDates)
            if (typeof searchParams.date === 'string' && isValidDateString(searchParams.date) && availableDates) {
                const closestDateIndex = findIndexOfDateInArray(availableDates, new Date(searchParams.date));
                setIndex(closestDateIndex);
            } else setIndex(availableDates.length - 1);
    }, [availableDates, index, searchParams]);

    // useEffect for setting active date by index
    useEffect(() => {
        if (index !== -1 && availableDates) {
            setActiveDate(availableDates[index]);
            const url = window.location.origin + window.location.pathname + '?';
            const newQuery = createNewQueryStringWithUpdatedDate(availableDates, index, searchParams);
            window.history.pushState({}, '', url + newQuery);
        }
    }, [availableDates, index, searchParams]);

    const { data: clouds } = useCloudsPerDateQuery(params, availableDates![index], searchParams);
    const { data: insights, isLoading: isInsightsLoading } = useInsightsPerDateQuery(
        params,
        availableDates![index], //runs when available dates and valid index is fetched
        showProcessingStatus,
        searchParams
    );
    const { data: customData } = useCustomDataQuery(params);

    //! unecessary when duplicate filtering is handled in database
    const useFilteredFeatures = (collection: FeatureCollection | undefined) => {
        return useMemo(() => {
            if (!collection) {
                return collection;
            }
            // Create a map to track seen ids
            const seenIds = new Map();

            // Filter out duplicates based on id
            collection.features = collection.features.filter((feature) => {
                if (!seenIds.has(feature?.properties?.uuid)) {
                    seenIds.set(feature?.properties?.uuid, true);
                    return true; // Include this feature
                }
                return false; // Exclude duplicate feature
            });

            return collection;
        }, [collection]); // Dependency array
    };

    return (
        <Wrapper>
            <ReportInfo
                index={index}
                setIndex={setIndex}
                currMap={map}
                availableDates={availableDates!}
                insights={insights}
                popupRef={popupRef}
                visibleLayers={visibleLayers}
                setVisibleLayers={setVisibleLayers}
                tour={tour}
                detectionConfidence={detectionConfidence}
                setDetectionConfidence={setDetectionConfidence}
                setSelectedFeature={setSelectedFeature}
                setProcessingStatus={setProcessingStatus}
            />
            {isInsightsLoading && <AbsoluteSpinner />}
            <OverwatchRight
                aisPositions={useFilteredFeatures(insights?.undetected_ais)}
                matchedVessels={useFilteredFeatures(insights?.matched_vessels)}
                darkVessels={useFilteredFeatures(insights?.dark_vessels)}
                staticObjects={useFilteredFeatures(insights?.static_objects)}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
                setHoveredFeature={setHoveredFeature}
                shipName={null}
            />
            <RMap
                insights={insights}
                clouds={clouds}
                customData={customData}
                metaData={metaData}
                activeDate={activeDate}
                availableDates={availableDates}
                isInsightsLoading={isInsightsLoading}
                map={map}
                popupRef={popupRef}
                setMap={setMap}
                setVisibleLayers={setVisibleLayers}
                detectionConfidence={detectionConfidence}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
                hoveredFeature={hoveredFeature}
                setHoveredFeature={setHoveredFeature}
            />
        </Wrapper>
    );
};
