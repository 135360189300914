import { useLayoutEffect, useMemo, useRef } from 'react';

export const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
    const callbackRef = useRef(callback);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    let timer: NodeJS.Timeout;

    const naiveDebounce = (func: (...args: any[]) => void, delayMs: number, ...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delayMs);
    };

    return useMemo(
        () =>
            (...args: any) =>
                naiveDebounce(callbackRef.current, delay, ...args),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [delay]
    );
};
