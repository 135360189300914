import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import constants from '../shared/constants';
import { getRequestHeaders, vake_api_server_url } from '../requests/helpers';
import { Spinner } from '../shared/styles/Spinner';
import { useAuth } from '../auth/AuthProvider';
import { useIsAdmin } from '../requests/reports';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

const Container = styled.div`
    width: 75vw;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    margin-top: 100px;
`;

const Table = styled.table`
    width: 75%;
    border-collapse: collapse;
    border-radius: 50px;
    color: none;
`;

const TableCell = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    border: none;
`;

interface OverWatchReport {
    start_time: string;
    end_time: string;
    description: string;
    created_at: string;
    uuid: string;
    url: string;
    name: string;
}

const TableBody = styled.tbody`
    td {
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.25); /* Adding weak horizontal lines */
        padding: 10px;
        text-align: centre;
    }
    &:hover {
        background-color: ${constants.colors.pallet.chilledBlue};
    }
`;

const useGetAllSubscriptions = () => {
    const { token } = useAuth();
    let url = `${vake_api_server_url}/dashboard/get-overwatch-subscriptions`;
    return useQuery<OverWatchReport[]>(['overwatch_reports'], async () => {
        const res = await fetch(url, {
            credentials: 'include',
            headers: getRequestHeaders(token),
        });

        if (!res.ok) {
            throw new Error(res.statusText);
        }

        return await res.json();
    });
};

const OverwatchReports: React.FC<any> = () => {
    const { data: overWatchReportItems, isLoading, isError } = useGetAllSubscriptions();
    const { token } = useAuth();
    const { data: isAdmin } = useIsAdmin(token);
    if (isError) {
        return (
            <Container>
                <div>Fetching data failed</div>
            </Container>
        );
    }
    if (isLoading) {
        return (
            <Wrapper>
                <AbsoluteSpinner />
            </Wrapper>
        );
    }
    return (
        <Container>
            {overWatchReportItems ? (
                <Table>
                    <thead>
                        <tr>
                            <th>Report Name</th>
                            {isAdmin && <th>Customer Group</th>}
                            <th>Report Created</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                        </tr>
                    </thead>

                    {overWatchReportItems.map((report, i) => (
                        <TableBody key={i}>
                            <tr>
                                <TableCell>
                                    <a
                                        href={window.location.origin + '/overwatch/' + report.uuid}
                                        target="_blank"
                                        rel="noreferrer" // Security reasons for old browsers
                                    >
                                        {report.description}
                                    </a>
                                </TableCell>
                                {isAdmin && (
                                    <TableCell>{report.name.charAt(0).toUpperCase() + report.name.slice(1)}</TableCell>
                                )}
                                {report.created_at ? (
                                    <TableCell>
                                        {new Date(report.created_at).toLocaleString().replace(',', '')}
                                    </TableCell>
                                ) : (
                                    <TableCell>-</TableCell>
                                )}
                                {report.start_time ? (
                                    <TableCell>
                                        {new Date(report.start_time).toLocaleString().replace(',', '')}
                                    </TableCell>
                                ) : (
                                    <TableCell>-</TableCell>
                                )}
                                {report.end_time ? (
                                    <TableCell>{new Date(report.end_time).toLocaleString().replace(',', '')}</TableCell>
                                ) : (
                                    <TableCell>- </TableCell>
                                )}
                            </tr>
                        </TableBody>
                    ))}
                </Table>
            ) : (
                <div>No registered overwatch reports</div>
            )}
        </Container>
    );
};

export default OverwatchReports;
