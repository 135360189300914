import { Feature } from 'geojson';
import Map, { Source, Layer } from 'react-map-gl';
import styled from 'styled-components';
import DrawControl from '../../../shared/components/rmgl/DrawControl';

const MapContainer = styled.div<{ isLoadingData: Boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isLoadingData ? 0.5 : 1)};
`;

export const OrderOverwatchMap: React.FC<{
    drawMode: boolean;
    toggleDrawMode: (value: boolean) => void;
    polygon: Feature | undefined;
    setPolygon: (value: Feature | undefined) => void;
}> = ({ drawMode, toggleDrawMode, polygon, setPolygon }) => {
    const viewport = {
        latitude: 57.33870347771735,
        longitude: 2.042840975438542,
        zoom: 5,
    };

    return (
        <MapContainer isLoadingData={false}>
            <Map
                mapStyle="mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms"
                mapboxAccessToken="pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw"
                initialViewState={viewport}
            >
                {drawMode && (
                    <DrawControl
                        position="bottom-left"
                        displayControlsDefault={false}
                        defaultMode="draw_polygon"
                        onCreate={(res: any) => {
                            setPolygon(res.features[0]);
                            toggleDrawMode(false);
                        }}
                        controls={{
                            polygon: true,
                            trash: true,
                        }}
                    />
                )}
                {polygon && (
                    <Source id="aoi" type="geojson" data={polygon}>
                        <Layer
                            id="aoi_fill"
                            type="fill"
                            paint={{
                                'fill-color': '#0080ff', // blue color fill
                                'fill-opacity': 0.3,
                            }}
                        />
                        <Layer
                            id="aoi_outline"
                            type="line"
                            paint={{
                                'line-color': '#555',
                                'line-width': 3,
                            }}
                        />
                    </Source>
                )}
            </Map>
        </MapContainer>
    );
};
