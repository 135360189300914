import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getRequestHeaders, vake_api_server_url } from '../requests/helpers';
import { useAuth } from '../auth/AuthProvider';
import { Spinner } from '../shared/styles/Spinner';
import constants, { mmsiFlags } from '../shared/constants';
import { CenteredElement } from './Report/styles/Wrappers';
import { ButtonLink } from '../shared/styles/Buttons';
import { markAsInteresting, updatePFDescription, updatePFReportName } from '../requests/reports';
import { checkIfValidUUID } from './VisualizeCandidates';
import { useDebounce } from '../utils/debounce';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
`;

const ReportsTable = styled.table`
    width: 85%;
    margin-top: 1.5vw;
`;

const MMSIHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const InformationHolder = styled.div<{ $isLast?: boolean | null }>`
    display: flex;
    align-items: center;
    border-bottom: ${(props) => (props.$isLast ? '1px solid rgba(255, 255, 255, 0.7)' : '0px solid transparent')};
    &:hover {
        background-color: ${constants.colors.pallet.chilledBlue};
    }
`;

const LinkHolder = styled.a`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;
    text-decoration: none;
`;

const DetailColumn = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    height: 60px;
    margin: 20px 0;
`;

const ListSpace = styled.div`
    height: 3vw;
    width: 90%;
    margin: 3vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const DescriptionInput = styled.textarea`
    &:focus {
        height: 12vh;
    }
    margin-right: 0.5vw;
`;

const ReportNameInput = styled.input`
    font-size: 18px;
    background: transparent;
    border: none;
    color: #fff;
`;

const FlagButton = styled(ButtonLink)`
    padding: 0;
    height: 32px;
`;

interface PathfinderReport {
    id: string;
    url?: string;
    bucket_path?: string;
    mmsi?: string;
    uuid?: string;
    mark?: string;
    from?: string;
    to?: string;
    name?: string;
    created_at?: string;
    report_name?: string;
    description?: string;
}
interface DateIntervals {
    from: string;
    to: string;
}

interface PathfinderDetails {
    url: string;
    emoji: string;
    dateInterval: DateIntervals;
    uuid?: string;
    mark?: string;
    customer_group_name?: string;
    created_at?: string;
    report_name: string;
    description: string;
}
interface PathfindersDict {
    [mmsi: string]: PathfinderDetails[];
}
interface MarkReportProps {
    index: number;
    mmsi: string;
    reportUUID?: string;
    currMark?: string;
    description: string;
    reportName: string;
}

const useGetUserPathfinderSubscriptions = () => {
    const { token } = useAuth();
    let url = `${vake_api_server_url}/dashboard/get-pathfinder-subscriptions`;
    return useQuery<PathfinderReport[]>(['pathfinder_reports'], async () => {
        const res = await fetch(url, {
            credentials: 'include',
            headers: getRequestHeaders(token),
        });

        if (!res.ok) {
            throw new Error(res.statusText);
        }

        return await res.json();
    });
};

const returnValidURL = (Url: string | undefined, bucketPath: string | undefined) => {
    if (Url) {
        return Url;
    } else if (bucketPath) {
        return bucketPath;
    }
    return 'no url';
};
const returnListOfPFValues = (id: string | undefined, bucketPath: string | undefined) => {
    if (id) {
        return id.split('_');
    } else if (bucketPath) {
        return bucketPath.split('_');
    }
    return [];
};

const compareReportTimestamps = (a: PathfinderDetails, b: PathfinderDetails): number => {
    const a_from = new Date(a.dateInterval.from);
    const b_from = new Date(b.dateInterval.from);
    if (a_from < b_from) {
        return -1;
    } else if (a_from > b_from) {
        return 1;
    }
    if (new Date(a.dateInterval.to) < new Date(b.dateInterval.to)) {
        return -1;
    } else {
        return 1;
    }
};

const addToDict = (
    addTo: PathfindersDict | null,
    mmsi: string,
    pathfinderObject: PathfinderDetails
): PathfindersDict | null => {
    if (addTo) {
        let newData = JSON.parse(JSON.stringify(addTo)); // Deep copy
        if (mmsi in newData) {
            const sameMMSIReports = newData[mmsi];
            sameMMSIReports.push(pathfinderObject);
            sameMMSIReports.sort(compareReportTimestamps);
            newData[mmsi] = sameMMSIReports;
        } else {
            newData[mmsi] = [pathfinderObject];
        }
        return newData;
    }
    return addTo;
};

const removeFromDict = (
    removeFrom: PathfindersDict | null,
    mmsi: string,
    index: number,
    setHasFlaggedData: (value: boolean) => void
): PathfindersDict | null => {
    if (removeFrom) {
        let newData = JSON.parse(JSON.stringify(removeFrom)); // Deep copy
        if (newData[mmsi].length < 2) {
            delete newData[mmsi];
        } else {
            newData[mmsi].splice(index, 1);
        }
        if (Object.keys(newData).length === 0) {
            setHasFlaggedData(false);
        }
        return newData;
    }
    return removeFrom;
};

// TODO: In the future this class and OverwatchReports can be shared
const PathfinderReports: FC<any> = () => {
    const { token } = useAuth();
    const { data: pathfinderReportItems, isLoading, isError } = useGetUserPathfinderSubscriptions();
    const [cleanPathfinderData, setCleanPathfinderData] = useState<PathfindersDict | null>(null);
    const [flaggedPathfinderData, setFlaggedPathfinderData] = useState<PathfindersDict | null>(null);
    const [hasFlaggedData, setHasFlaggedData] = useState<boolean>(false);

    useEffect(() => {
        if (pathfinderReportItems) {
            let tempCleanedPathfinderData: PathfindersDict = {};
            let tempFlaggedPathfinderData: PathfindersDict = {};
            pathfinderReportItems.forEach((item) => {
                const current_url: string = returnValidURL(item.id, item.bucket_path);
                const vesselInfo: string[] = returnListOfPFValues(item.id, item.bucket_path);
                if (current_url !== 'no url' && vesselInfo.length > 0) {
                    const mmsi = vesselInfo[0];
                    const singlePathfinder = {
                        url: window.location.origin + '/pathfinder/' + current_url,
                        dateInterval: { from: vesselInfo[1], to: vesselInfo[2] },
                        emoji: mmsiFlags[mmsi.substring(0, 3)],
                        uuid: item.uuid,
                        mark: item.mark,
                        customer_group_name: item.name,
                        created_at: item.created_at,
                        report_name: item.report_name ? item.report_name : '',
                        description: item.description ? item.description : '',
                    };
                    if (singlePathfinder.mark === 'important') {
                        // Handle flagged reports
                        setHasFlaggedData(true);
                        if (mmsi in tempFlaggedPathfinderData) {
                            tempFlaggedPathfinderData[mmsi].push(singlePathfinder);
                        } else {
                            tempFlaggedPathfinderData[mmsi] = [singlePathfinder];
                        }
                    } else {
                        if (mmsi in tempCleanedPathfinderData) {
                            tempCleanedPathfinderData[mmsi].push(singlePathfinder);
                        } else {
                            tempCleanedPathfinderData[mmsi] = [singlePathfinder];
                        }
                    }
                }
            });
            setFlaggedPathfinderData(tempFlaggedPathfinderData);
            setCleanPathfinderData(tempCleanedPathfinderData);
        }
    }, [pathfinderReportItems]);

    const markSelectedReport = ({ index, mmsi, reportName, reportUUID, currMark, description }: MarkReportProps) => {
        if (reportUUID && token && checkIfValidUUID(reportUUID) && flaggedPathfinderData && cleanPathfinderData) {
            const newMark = currMark === 'important' ? null : 'important';
            markAsInteresting(reportUUID, newMark, token);
            if (currMark === 'important') {
                let pathfinderObject = flaggedPathfinderData[mmsi][index];
                pathfinderObject.report_name = reportName;
                pathfinderObject.description = description;
                pathfinderObject.mark = undefined;
                setCleanPathfinderData(addToDict(cleanPathfinderData, mmsi, pathfinderObject));
                setFlaggedPathfinderData(removeFromDict(flaggedPathfinderData, mmsi, index, setHasFlaggedData));
            } else {
                let pathfinderObject = cleanPathfinderData[mmsi][index];
                pathfinderObject.report_name = reportName;
                pathfinderObject.description = description;
                pathfinderObject.mark = 'important';
                setFlaggedPathfinderData(addToDict(flaggedPathfinderData, mmsi, pathfinderObject));
                setCleanPathfinderData(removeFromDict(cleanPathfinderData, mmsi, index, setHasFlaggedData));
                setHasFlaggedData(true);
            }
        }
    };

    if (isError) {
        return <CenteredElement>Fetching data failed</CenteredElement>;
    }
    if (isLoading) {
        return (
            <CenteredElement>
                <Spinner />
            </CenteredElement>
        );
    }
    return (
        <Container>
            {flaggedPathfinderData && hasFlaggedData ? (
                <>
                    <h3>Starred Reports</h3>
                    <PathfinderReportsBlock
                        pathfinderData={flaggedPathfinderData}
                        token={token}
                        markSelectedReport={markSelectedReport}
                    />
                    <ListSpace />
                </>
            ) : null}
            {cleanPathfinderData ? (
                <>
                    {hasFlaggedData ? <h3>Other Reports</h3> : null}
                    <PathfinderReportsBlock
                        pathfinderData={cleanPathfinderData}
                        token={token}
                        markSelectedReport={markSelectedReport}
                    />
                </>
            ) : null}
            {!flaggedPathfinderData && !cleanPathfinderData ? (
                <CenteredElement>We found no data for this user</CenteredElement>
            ) : null}
        </Container>
    );
};

const PathfinderReportsBlock: FC<{
    pathfinderData: PathfindersDict;
    token: null | string | undefined;
    markSelectedReport: (props: MarkReportProps) => void;
}> = ({ pathfinderData, markSelectedReport, token }) => {
    return (
        <ReportsTable>
            <tbody>
                {Object.entries(pathfinderData).map(([mmsi, v]) => {
                    return (
                        <tr key={mmsi}>
                            <td>
                                <MMSIHolder>
                                    <h2 style={{ fontSize: '40px', paddingRight: '10px' }}>{v[0].emoji}</h2>
                                    {mmsi}
                                </MMSIHolder>
                            </td>
                            <td>
                                {v.map((details, index) => {
                                    return (
                                        <ReportRow
                                            key={details.created_at}
                                            details={details}
                                            index={index}
                                            totalLength={v.length}
                                            mmsi={mmsi}
                                            token={token}
                                            markSelectedReport={markSelectedReport}
                                        />
                                    );
                                })}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </ReportsTable>
    );
};

const ReportRow: FC<{
    details: PathfinderDetails;
    index: number;
    totalLength: number;
    mmsi: string;
    token: null | string | undefined;
    markSelectedReport: (props: MarkReportProps) => void;
}> = ({ details, index, totalLength, mmsi, markSelectedReport, token }) => {
    const [description, setDescription] = useState<string>(details.description);
    const [reportName, setReportName] = useState<string>(details.report_name);

    const updateReportDescription = (newReportDescription?: string, reportUUID?: string) => {
        if (reportUUID && typeof newReportDescription === 'string') {
            updatePFDescription(newReportDescription, reportUUID, token);
        }
    };

    const updateReportName = (reportName?: string, reportUUID?: string) => {
        if (reportUUID && typeof reportName === 'string') {
            updatePFReportName(reportName, reportUUID, token);
        }
    };

    const debounceDescriptionHandler = useDebounce(
        (newDescription) => updateReportDescription(newDescription, details.uuid),
        500
    );
    const debounceReportNameHandler = useDebounce(
        (newReportName) => updateReportName(newReportName, details.uuid),
        500
    );
    return (
        <InformationHolder $isLast={index === totalLength - 1}>
            <ReportNameInput
                value={reportName}
                onChange={(e) => {
                    setReportName(e.target.value);
                    debounceReportNameHandler(e.target.value);
                }}
            />
            <LinkHolder href={details.url} target={'_blank'}>
                <DetailColumn>
                    <div>
                        {details.dateInterval.from ? 'Start time: ' + details.dateInterval.from.split('T')[0] : ' - '}
                    </div>
                    <div>{details.dateInterval.to ? 'End time: ' + details.dateInterval.to.split('T')[0] : ' - '}</div>
                </DetailColumn>
                <DetailColumn>
                    <div>{details.created_at ? 'Created at: ' + details.created_at.split('T')[0] : ' - '}</div>
                    {details.customer_group_name ? <div>{'By: ' + details.customer_group_name}</div> : null}
                </DetailColumn>
            </LinkHolder>
            <DescriptionInput
                placeholder="Description"
                value={description}
                onChange={(e) => {
                    setDescription(e.target.value);
                    debounceDescriptionHandler(e.target.value);
                }}
            />
            <FlagButton
                background={details.mark === 'important' ? '#f2ce2e' : constants.colors.pallet.secondary}
                onClick={() =>
                    markSelectedReport({
                        index,
                        mmsi,
                        reportUUID: details.uuid,
                        currMark: details.mark,
                        description,
                        reportName,
                    })
                }
            >
                <img src="/images/icons/star.svg" alt="star" />
            </FlagButton>
        </InformationHolder>
    );
};

export default PathfinderReports;
